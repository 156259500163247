import React, { Component, Suspense } from "react";
import Layout from "./containers/Layout/Layout";
import { Route } from "react-router-dom";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import "./App.scss";
import Loading from "./containers/Loading/Loading";
const Documentation = React.lazy(() =>
  import("./containers/Documentation/Documentation")
);
const Home = React.lazy(() =>
  import("./containers/Home/Home")
);
class App extends Component {
  render() {
    let routeArr = [];

    routeArr.push(
      <Route key="home" exact path="/home"
        render={() => (
          <Suspense fallback={<Loading />}>
            <Home />
          </Suspense>
        )}
      ></Route>)
    routeArr.push(<Route key="documentation" exact path="/documentation"
      render={() => (
        <Suspense fallback={<Loading />}>
          <Documentation />
        </Suspense>
      )}></Route>)
    routeArr.push(<Redirect key="homeRedirect" to="/home"></Redirect>)
    return (
      <BrowserRouter>
        <Layout>
          <React.Fragment>
            <Switch>
              {routeArr}
            </Switch>
          </React.Fragment>
        </Layout>
      </BrowserRouter>
    );
  }
}

export default App;
