import React, { Component } from 'react'
import { Nav, Navbar, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import logo from '../../assets/Logo.svg';
import classes from './Layout.module.scss';
import { NavLink } from 'react-router-dom';
export default class Layout extends Component {
    render() {
        const homeLink = props => (
            <NavLink
                key="homeLink"
                //   activeStyle={activeStyle}
                exact
                to="/home"
                {...props}
            />
        );
        // const documentationLink = props => (
        //     <NavLink
        //         key="documentationLink"
        //         //   activeStyle={activeStyle}
        //         exact
        //         to="/documentation"
        //         {...props}
        //     />
        // );
        return (
            <React.Fragment>
                <Navbar className={classes.navbar} variant="dark" expand="xl">
                    <Navbar.Brand as={homeLink}>
                        <img
                            src={logo}
                            width="45"
                            // height="30"
                            className="d-inline-block align-top"
                        />
                        <span className={classes.pageTitle}>{' The Prodicus Group'}</span>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        {/* <Nav className="mr-auto">
                            <Nav.Link as={documentationLink}>Documentation</Nav.Link>
                        </Nav> */}
                    </Navbar.Collapse>
                </Navbar>
                {/* <Navbar fixed className={classes.navbar} color="rgba(10,10,10,0.4)" expand="xl">
                    <Navbar.Brand as={homeLink}>
                        <img
                            src={logo}
                            width="45"
                            // height="30"
                            className="d-inline-block align-top"
                        />
                        {' The Prodicus Group'}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link as={documentationLink}>Documentation</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar> */}
                <main style={{ height: "100%" }}>{this.props.children}</main>
            </React.Fragment>
        )
    }
}

